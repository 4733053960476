<template>
	<div class="animated fadeIn">
		<div class="container">
			<coupon-form
				:form-import="form"
				:create-new-ticket="true"
				:processing="processing"
				:errors-import="errors.errors"
				@submit="generateCoupon" />
		</div>
	</div>
</template>
<script>

import Coupon from '@/util/Coupons';
import { Coupons } from '@/translations';
import CouponForm from './components/CouponForm';

export default {
	name: 'CouponGenerate',
	messages: [Coupons],
	components: {
		CouponForm,
	},
	data() {
		return {
			coupon: new Coupon(),
			form: {
				username: '',
				quantity: 1,
			},
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			return this.coupon.data.errors;
		},
		processing() {
			return this.coupon.data.loading;
		},
	},
	mounted() {
		this.setFocus('username');
	},
	methods: {
		generateCoupon(payload) {
			this.coupon.generateCoupons(payload).then(() => {
				const { quantity } = payload;
				const toastMessage = quantity === 1 ? this.translate('coupon_created') : this.translate('coupons_created', { quantity });
				this.alert.toast('success', toastMessage);
				this.$router.push({ name: 'CouponsActive' });
			}).catch(() => { this.data = payload; });
		},
	},
};
</script>
