<template>
	<div>
		<b-form
			@submit.prevent="couponInfoSubmit"
			@keydown="clearError($event.target.name);"
			@change="clearError($event.target.name);">
			<div class="form-group">
				<search
					id="username"
					v-model="form.username"
					:label-text="translate('username')"
					:endpoint="endpoint"
					required
					:empty-result-message="translate('data_not_found')"
					:default-params="{ role: 'distributor', status: 'active,inactive' }"
					:custom-error="hasError('username') ? errors['username'][0] : false"
					:init-value="form.username"
					q="label"
					return-key="username"
					@input="clearError('username')" />
			</div>

			<input-text
				id="quantity"
				:label="translate('quantity')"
				:setter-value="form.quantity"
				:errors="errors['quantity']"
				required
				type="number"
				@dataChanged="form.quantity = $event" />

			<button
				:disabled="Object.keys(errors).length > 0 || processing"
				class="btn btn-primary float-right">
				<template v-if="!processing">
					{{ translate('save') }}
				</template>
				<template v-else>
					<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
				</template>
			</button>
		</b-form>
	</div>
</template>
<script>

import InputText from '@/components/InputText';
import {
	Profile, Coupons,
} from '@/translations';
import { SEARCH_USERS } from '@/config/endpoint';

export default {
	name: 'CouponForm',
	messages: [Profile, Coupons],
	components: {
		InputText,
	},
	props: {
		errorsImport: {
			type: Object,
			default: () => ({}),
		},
		formImport: {
			type: Object,
			default: () => ({}),
		},
		processing: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			endpoint: SEARCH_USERS.endpoint,
		};
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
	},
	methods: {
		couponInfoSubmit() {
			this.$emit('submit', this.form);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
